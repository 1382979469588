import { default as catalogue27KWYdMlReMeta } from "/www/wwwroot/server/pages/catalogue.vue?macro=true";
import { default as indexgmzvTe8cxwMeta } from "/www/wwwroot/server/pages/index.vue?macro=true";
import { default as luxury_45itemY0nxVuryQXMeta } from "/www/wwwroot/server/pages/luxury-item.vue?macro=true";
import { default as menuM1Sb5CfnKZMeta } from "/www/wwwroot/server/pages/menu.vue?macro=true";
import { default as promotion3CBkiH082xMeta } from "/www/wwwroot/server/pages/promotion.vue?macro=true";
import { default as tncDkxlweoM7TMeta } from "/www/wwwroot/server/pages/tnc.vue?macro=true";
import { default as welcome_45goldml3IKhy0FkMeta } from "/www/wwwroot/server/pages/welcome-gold.vue?macro=true";
export default [
  {
    name: catalogue27KWYdMlReMeta?.name ?? "catalogue",
    path: catalogue27KWYdMlReMeta?.path ?? "/catalogue",
    meta: catalogue27KWYdMlReMeta || {},
    alias: catalogue27KWYdMlReMeta?.alias || [],
    redirect: catalogue27KWYdMlReMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: indexgmzvTe8cxwMeta?.name ?? "index",
    path: indexgmzvTe8cxwMeta?.path ?? "/",
    meta: indexgmzvTe8cxwMeta || {},
    alias: indexgmzvTe8cxwMeta?.alias || [],
    redirect: indexgmzvTe8cxwMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/index.vue").then(m => m.default || m)
  },
  {
    name: luxury_45itemY0nxVuryQXMeta?.name ?? "luxury-item",
    path: luxury_45itemY0nxVuryQXMeta?.path ?? "/luxury-item",
    meta: luxury_45itemY0nxVuryQXMeta || {},
    alias: luxury_45itemY0nxVuryQXMeta?.alias || [],
    redirect: luxury_45itemY0nxVuryQXMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/luxury-item.vue").then(m => m.default || m)
  },
  {
    name: menuM1Sb5CfnKZMeta?.name ?? "menu",
    path: menuM1Sb5CfnKZMeta?.path ?? "/menu",
    meta: menuM1Sb5CfnKZMeta || {},
    alias: menuM1Sb5CfnKZMeta?.alias || [],
    redirect: menuM1Sb5CfnKZMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: promotion3CBkiH082xMeta?.name ?? "promotion",
    path: promotion3CBkiH082xMeta?.path ?? "/promotion",
    meta: promotion3CBkiH082xMeta || {},
    alias: promotion3CBkiH082xMeta?.alias || [],
    redirect: promotion3CBkiH082xMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/promotion.vue").then(m => m.default || m)
  },
  {
    name: tncDkxlweoM7TMeta?.name ?? "tnc",
    path: tncDkxlweoM7TMeta?.path ?? "/tnc",
    meta: tncDkxlweoM7TMeta || {},
    alias: tncDkxlweoM7TMeta?.alias || [],
    redirect: tncDkxlweoM7TMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/tnc.vue").then(m => m.default || m)
  },
  {
    name: welcome_45goldml3IKhy0FkMeta?.name ?? "welcome-gold",
    path: welcome_45goldml3IKhy0FkMeta?.path ?? "/welcome-gold",
    meta: welcome_45goldml3IKhy0FkMeta || {},
    alias: welcome_45goldml3IKhy0FkMeta?.alias || [],
    redirect: welcome_45goldml3IKhy0FkMeta?.redirect,
    component: () => import("/www/wwwroot/server/pages/welcome-gold.vue").then(m => m.default || m)
  }
]